import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import { StaticImage } from "gatsby-plugin-image";

import "../components/sections/passion.scss";

const MagnifyingGlasses = () => {
  return (
    <Layout activeMenu="special-glasses">
      <Seo
        title="besondere Sehhilfen"
        description="Sehen ist Lebensqualität. Jautzke Augenoptik bietet für Menschen mit starker Sehbeeinträchtigung verschiedene Lösungen an - sei es im Bereich der Lupen, Lichtschutz oder der richtigen Belichtung beim Lesen."
      ></Seo>
      <section className="container">
        <div className="section-title">
          <h2>Filterung</h2>
        </div>
        <div className="section-sub-title">
          Weltweit leiden ca. 10-15 % der Bevölkerung an Migräne. Mit spezillen
          Gläsern können die Belastungen durch Licht- und Blendempfindlichkeit
          im Innenraum bzw. Außenbereich sowie Bildschirm- und Leuchtstoffröhren
          Licht spürbar verringern.
        </div>
        <div className="passion-content">
          <div className="passion-item text-right">
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              <div className="headline">Spezialbrillengläser</div>
              <div className="title">MIGRÄNE-Comfort</div>
              <div className="description">
                <ul>
                  <li>
                    Spezielle Filter zur Entlastung der Augen, z.B. bei Kunst-
                    und LED-Licht
                  </li>
                  <li>
                    Geringe Farbveränderung, dadurch schnelle Eingewöhnung
                  </li>
                  <li>Verringerung der Lichtempfindlichkeit</li>
                  <li>Präventiver Schutz vor Lichtreizen</li>
                </ul>
                Dies führt besonders bei Personen mit häufigen Kopfschmerzen und
                Migrängen zu einen deutlich höheren Tragekomfort.
              </div>
            </div>
            <div
              className="image"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                alt="MIGRAENE Filter"
                src={"../images/special/MIGRAENE_Filter_Brille.jpg"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="section-title">
          <h2>vergrößernde Sehhilfen</h2>
        </div>
        <div className="section-sub-title">
          <b>Sehen ist Lebensqualität</b>, gerade für Menschen, die trotz
          Sehbeeinträchtigung weiterhin unabhängig von der Unterstützung Dritter
          ihren vielseitigen Interessen nachgehen möchten, bieten wie
          verschiedene Lösungen an. Folgend ein paar Beispiele.
          <br />
          <br />
          <b>Kommen Sie vorbei, wir beraten Sie gerne!</b>
        </div>
        <div className="passion-content">
          <div className="passion-item text-right">
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              <div className="headline">elektronische Lupe</div>
              <div className="title">eMag 50 HD</div>
              <div className="description">
                Sie ist klein genug, um auch unterwegs überall griffbereit zu
                sein – etwa beim Einkaufsbummel oder im Restaurant. Ihr Display
                ist dennoch groß genug, um – schräg aufgestellt – damit bequem
                Zeitung zu lesen. Die integrierte Kamera in HD-Qualität, die
                individuell einstellbare Vergrößerung, diverse Farbkombinationen
                und der 5-Zoll-TFT LCD Breitbildschirm bedingen eine brillante
                Bildwiedergabe und ergonomische Bedienbarkeit.
              </div>
            </div>
            <div
              className="image"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                alt="eMag 50 HD"
                src={
                  "../images/special/Milieu_eMag50HD_KonzertkartenSchlüssel_B_3189_450x278px.jpg"
                }
              />
            </div>
          </div>
          <div className="passion-item text-right">
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              <div className="headline">Kamera mit Colour-Boost-Funktion</div>
              <div className="title">eMag 240 HD flex</div>
              <div className="description">
                Eine viel größere Flexibilität bietet beispielsweise die eMag HD
                flex. Denn mit der um 3 Achsen schwenkbaren Kamera werden nicht
                nur Dokumente lesbar, sondern man kann auch entfernte Objekte
                erkennen und sich selbst quasi im Spiegel betrachten. Neben der
                ebenfalls neu entwickelten Colour-Boost-Funktion für
                brillantere, kontrastreichere Farben bietet die Kamera 32
                weitere Darstellmodi in Vergrößerungen von 1,4- bis zu 131-fach.
                Zudem ist unter der Kamera viel Platz zum Basteln, Schreiben
                oder Umblättern.
              </div>
            </div>
            <div
              className="image"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                alt="eMag 240 HD flex"
                src={
                  "../images/special/2a_Bild gross Anw_eMag240HDflex_ColorBoostTischFernbed.jpg"
                }
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="section-title">
          <h2>das richtige Licht</h2>
        </div>
        <div className="section-sub-title">
          Oft ist nicht nur die Schriftgröße das Problem beim Erkennen von
          Buchstaben oder Details. Genauso wichtig ist eine gute Beleuchtung.
          Denn sowohl der normale Alterungsprozess als auch degenerative
          Augenerkrankungen können dazu führen, dass bei gleichem Komfort 15mal
          so viel Licht benötigt wird. Hierfür bitten wir ebenfalls Lösungen!
        </div>
        <div className="passion-content">
          <div className="passion-item text-right">
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              <div className="headline">Tischlampe für mehr Komfort</div>
              <div className="title">MULTILIGHT Pro</div>
              <div className="description">
                Die speziell für den LowVision-Bereich entwickelte Leuchtenserie
                MULTILIGHT gibt es in den Nuancen "gelblich-weiß",
                "neutral-weiß" und "kalt-weiß". Ob als Stehlampe für die
                Leseecke, als Lesepult für die Zeitung am Frühstückstisch oder
                die Pocket-Leuchte für unterwegs: für jeden individuellen Bedarf
                gibt es die passende Beleuchtung. Sie verbessert das
                Kontrastsehen und damit die Sehschärfe deutlich und trägt somit
                zum Erhalt der Lebensqualität trotz einer Sehschwäche bei.
              </div>
            </div>
            <div
              className="image"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                alt="MULTILIGHT Pro"
                src={
                  "../images/special/2a_Bild_MULTILIGHT_Pro_Anwendung_1350x834.png"
                }
              />
            </div>
          </div>
          <div className="passion-item text-right">
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              <div className="headline">Luppen mit Licht</div>
              <div className="title">ERGO-Lux MP mobil</div>
              <div className="description">
                Die ergonomisch optimierten Lupen der leicht zu bedienenden
                Lupenmodelle der Serie "ERGO-Lux MP mobil" bieten nicht nur
                überall gleichmäßig helles Licht, sondern zusätzlich eine
                Boost-Funktion zur Optimierung der Ausleuchtung, wenn es mal
                besonders dunkel ist. Diese Lupen sind auch in unterschiedlichen
                Lichtfarben, von Warmweiß über Neutral- bis zu Kaltweiß
                erhältlich. Dies ist ebenfalls wichtig für den individuellen
                Seheindruck. Denn je nach Art der Sehbehinderung kann ein
                anderes Lichtspektrum optimal sein.
              </div>
            </div>
            <div
              className="image"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                alt="eMag 240 HD flex"
                src={
                  "../images/special/Milieu_ERGOmobi_Speisekarte_10Dlinks_450x278px.jpg"
                }
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="section-title">
          <h2>Sonnenschutz</h2>
        </div>
        <div className="section-sub-title">
          Bei starker Lichteinstrahlung brauchen empfindliche Augen besonderen
          Schutz. Während normalsichtige Menschen sich leicht mit einer
          einfachen Sonnenbrille schützen können, reicht diese für Menschen mit
          Sehbehinderungen oft nicht aus. Auch für diesen Fall helfen wir Ihnen
          weiter!
        </div>
        <div className="passion-content">
          <div className="passion-item text-right">
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              <div className="headline">Kanten- und Comfortfilter</div>
              <div className="title">Lichtschutzbrillen</div>
              <div className="description">
                Helfen können spezielle Brillengläser, die nicht nur abdunkeln
                und das schädliche UV-Licht abblocken, sondern auch die
                besonders stark streuenden blauen Lichtanteile ganz oder
                teilweise herausfiltern. Solche Kanten- oder Comfortfilter gibt
                es zum Beispiel in Lichtschutzbrillen. Sie reduzieren die
                Blendung, verschärfen Kontraste und Seheindruck und erleichtern
                die Anpassung an unterschiedliche Lichtverhältnisse. Die
                Spezialgläser gibt es sowohl als Komplettmodelle als auch in
                Form von Auf- und Übersetzbrillen.
              </div>
            </div>
            <div
              className="image"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                alt="MULTILIGHT Pro"
                src={"../images/special/Bild_AMD-Comfort_Prod_1350x834.jpg"}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MagnifyingGlasses;
